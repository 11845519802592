/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { usePathname } from 'next/navigation'
import { useWindowSize } from 'react-use'
import { Theme } from '@/types/util'
import { HeaderContext, HeaderStateProps } from './header.context'

const DESKTOP_MAX_SCREEN = 1025

export const useHeaderState = () => {
  const { state, setState } = useContext(HeaderContext)
  const { width } = useWindowSize()
  const pathname = usePathname()
  const { opened } = state

  const dispatch = useCallback(
    (payload: Partial<HeaderStateProps>) => {
      setState((state) => ({ ...state, ...payload }))
    },
    [state]
  )

  const onToggleMenu = useCallback(
    (opened: boolean) => dispatch({ opened }),
    [dispatch]
  )

  const closeMenu = () => {
    opened && onToggleMenu(false)
  }

  useEffect(() => {
    if (width >= DESKTOP_MAX_SCREEN) {
      opened && dispatch({ opened: false })
    }
  }, [width])

  useEffect(() => {
    dispatch({ opened: false })
  }, [pathname])

  const theme = useMemo(() => {
    const isHome = pathname === '/'
    return (isHome ? 'dark' : 'light') as Theme
  }, [pathname])

  return {
    ...state,
    theme,
    dispatch,
    onToggleMenu,
    closeMenu
  }
}
