import { SvgName } from '@/svg'

export type ContactProps = {
  title: string
  href: string
  startIcon?: SvgName
}

export const contacts: ContactProps[] = [
  {
    title: '83 98832-6667',
    href: 'https://wa.me/5583988326667',
    startIcon: 'phone'
  },
  {
    title: 'atendimento@zeniteconstrucoes.com.br',
    href: 'mailto:atendimento@zeniteconstrucoes.com.br',
    startIcon: 'mail'
  }
]
