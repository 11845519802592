import NextLink from 'next/link'
import { Vector } from '@/components/ui/vector'
import { HeaderReturnVariantProps } from './header.variants'

type HeaderBrandProps = {
  styles: HeaderReturnVariantProps
}

const HeaderBrand = ({ styles }: HeaderBrandProps) => {
  return (
    <NextLink href="/" className={styles.brandLink()} title="Zênite">
      <Vector name="brand" className={styles.brand()} />
    </NextLink>
  )
}

export default HeaderBrand
