'use client'

import { Link } from '@/components/ui/link'
import { contacts } from '@/config/contacts'
import { menu } from './header.menu'
import { HeaderReturnVariantProps } from './header.variants'
import { useHeaderState } from './use-header-state'

type HeaderNavProps = {
  styles: HeaderReturnVariantProps
}

const HeaderNav = ({ styles }: HeaderNavProps) => {
  const { theme } = useHeaderState()
  const buttonColor = theme === 'dark' ? 'light' : 'dark'

  return (
    <nav className={styles.nav()}>
      <div className={styles.group({ className: 'w-full' })}>
        <ul
          className={styles.list({
            className: 'gap-4 desktop:gap-8'
          })}
        >
          {menu.map(({ href, title, startIcon }) => (
            <li key={title} className={styles.item()}>
              <Link
                className={styles.link()}
                href={href}
                title={title}
                activeClassName={
                  theme === 'dark' ? 'text-blue-100' : 'text-blue-500'
                }
                size="md"
                color={buttonColor}
                weight="medium"
                startIcon={startIcon}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={styles.group({
          className: 'lg:hidden xl:block'
        })}
      >
        <ul
          className={styles.list({
            className: 'gap-6 xs:gap-10'
          })}
        >
          {contacts.map(({ href, title, startIcon }) => (
            <li key={title} className={styles.item()}>
              <Link
                href={href}
                title={title}
                size="md"
                color={buttonColor}
                weight="medium"
                startIcon={startIcon}
              >
                <span>{title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default HeaderNav
