import { tv, VariantProps } from '@/utils/tv'

export const header = tv({
  slots: {
    base: ['fixed left-0 top-0 z-100', 'w-full'],
    bar: [
      'relative',
      'flex items-center justify-between',
      'h-24',
      'px-6 lg:px-8'
    ],
    brandLink: [
      'flex items-center justify-between',
      'mobile:outline-none',
      'focus:outline-dotted focus:outline-gray-400/30 focus:outline-offset-6',
      'mr-16'
    ],
    brand: ['w-28', 'outline-none'],
    nav: [
      'w-full',
      'flex flex-row items-center justify-between',
      'mobile:flex-col mobile:items-start mobile:gap-6 desktop:gap-4',
      'mobile:absolute mobile:z-50 mobile:left-0 mobile:top-full',
      'mobile:pt-6 mobile:pb-6 mobile:px-6',
      'mobile:transition-all mobile:duration-300',
      'mobile:h-[calc(100vh-80px)]',
      'mobile:h-[calc(100dvh-80px)]'
    ],
    group: [
      'mobile:w-full',
      'mobile:relative',
      'mobile:overflow-y-auto',
      'mobile:pb-14'
    ],
    list: ['flex items-center', 'mobile:flex-col mobile:items-start'],
    item: ['flex', 'mobile:w-full', 'desktop:mt-auto', 'mt-auto'],
    link: [
      'uppercase',
      'mobile:[&>span]:hidden',
      'mobile:[&>svg]:w-6 mobile:[&>svg]:h-6'
    ]
  },
  variants: {
    opened: {
      false: {
        nav: ['mobile:-translate-x-full']
      },
      true: {
        nav: ['mobile:translate-x-0']
      }
    },
    theme: {
      light: {
        base: ['bg-white'],
        nav: ['mobile:bg-white'],
        brand: ['text-blue-500']
      },
      dark: {
        base: ['bg-blue-500'],
        nav: ['mobile:bg-blue-500'],
        brand: ['text-white']
      }
    }
  },
  defaultVariants: {
    opened: false,
    theme: 'dark'
  }
})

export type HeaderVariantProps = VariantProps<typeof header>

export type HeaderReturnVariantProps = ReturnType<typeof header>
