import { SvgName } from '@/svg'

export type MenuItemProps = {
  title: string
  href: string
  startIcon?: SvgName
}

export const menu: MenuItemProps[] = [
  {
    title: 'Home',
    href: '/'
  },
  {
    title: 'Quem somos',
    href: '/quem-somos'
  },
  {
    title: 'Empreendimentos',
    href: '/empreendimentos'
  },
  {
    title: 'Fale Conosco',
    href: '/fale-conosco'
  }
]
